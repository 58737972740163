/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql } from "gatsby"
import get from "lodash/get"

import { findBySlug } from "../helpers"

import BenefitsModule from "../components/benefits-module"
import BenefitsImagesModule from "../components/benefits-images-module"
import CTAsModule from "../components/ctas-module"
import Hero from "../components/hero"
import Page from "../components/page"
import ProjectUnique from "../components/project-unique"
import ProcessModule from "../components/process-module"
import TestimonialsModule from "../components/testimonials-module"
import { paramsCheck } from "../functions/functions"

const CustomConstruction = ({ data }) => {
  paramsCheck()
  const renderHeroModule = () => {
    const heroBladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "custom-construction-hero-module",
    })
    const heroHeights = ["auto", 555]
    if (
      !heroBladePatternData ||
      !get(heroBladePatternData, "desktopImage.file.url")
    ) {
      return
    }

    return (
      <Hero
        customStyle={{
          minHeight: heroHeights,
          height: heroHeights,
        }}
        background={{
          type: "image",
          source: {
            desktopImage: heroBladePatternData.desktopImage,
          },
        }}
        body={heroBladePatternData.description.description}
        title={heroBladePatternData.title}
        type="secondary"
      />
    )
  }
  const renderBenefitsModule = () => {
    const benefitsModuleBladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "custom-construction-benefits-module",
    })

    if (!benefitsModuleBladePatternData) return

    return <BenefitsModule bladePatternData={benefitsModuleBladePatternData} />
  }
  const renderServicesModule = () => {
    const servicesModuleBladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "custom-construction-services-module",
    })

    if (!servicesModuleBladePatternData) return

    return <ProjectUnique data={servicesModuleBladePatternData} />
  }
  const renderProcessModule = () => {
    const processTextModuleBladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "custom-construction-process-text-module",
    })

    if (!processTextModuleBladePatternData) return

    return (
      <ProcessModule bladePatternData={processTextModuleBladePatternData} />
    )
  }
  const renderTestimonialsModule = () => {
    const testimonialsBladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "custom-construction-testimonials-module",
    })
    if (!testimonialsBladePatternData) return

    return (
      <TestimonialsModule bladePatternData={testimonialsBladePatternData} />
    )
  }
  const renderCtaModule = () => {
    const ctaModuleBladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "custom-construction-cta-module",
    })

    if (!ctaModuleBladePatternData) return

    return (
      <CTAsModule
        textStyle={{ color: theme => theme.colors.white }}
        bladePatternData={ctaModuleBladePatternData}
      />
    )
  }
  const renderBenefitsImageModule = () => {
    const bladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "custom-construction-benefits-list-module",
    })
    if (!bladePatternData) return
    return (
      <BenefitsImagesModule basicCardList={bladePatternData.contentCards} />
    )
  }

  return (
    <Page data={data.contentfulPage}>
      {renderHeroModule()}
      {renderBenefitsModule()}
      {renderBenefitsImageModule()}
      {renderTestimonialsModule()}
      {renderCtaModule()}
      {renderProcessModule()}
      {renderServicesModule()}
    </Page>
  )
}

export default CustomConstruction

export const query = graphql`
  query customConstructionPageQuery {
    contentfulPage(slug: { eq: "custom_construction" }) {
      ...PageData
      ...BladePatternData
    }

    allContentfulProject(limit: 3, sort: { fields: createdAt, order: DESC }) {
      edges {
        node {
          projectImages {
            id
            title
            fluid(maxWidth: 800) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          projectLocation {
            projectLocation
          }
          projectTitle {
            projectTitle
          }
          slug
        }
      }
    }
  }
`
