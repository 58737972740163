/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"

import Hero from "./hero"

const BenefitsFullwidthImagesModule = ({ basicCardList }) => {
  const heroHeights = ["auto", 600]

  return (
    <div sx={{ mt: "5px" }}>
      {basicCardList
        .filter(benefit => benefit.desktopImage)
        .map((benefit, idx) => (
          <Hero
            type="secondary"
            key={idx}
            customStyle={{
              minHeight: heroHeights,
              height: heroHeights,
              mb: "5px",
            }}
            bodyStyle={{
              fontWeight: [400, 400, 450, 450],
              mt: theme => theme.spacing.vertical.sm,
              "& li>p": {
                mt: 0,
              },
            }}
            background={{
              type: "image",
              source: {
                mobileImage: benefit.mobileImage,
                desktopImage: benefit.desktopImage,
              },
            }}
            title={benefit.title}
            verticalAlign="center"
            richText={benefit.description}
          />
        ))}
    </div>
  )
}

BenefitsFullwidthImagesModule.propTypes = {
  basicCardList: PropTypes.array,
}

export default BenefitsFullwidthImagesModule
